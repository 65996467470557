<template>
  <div>
    <cta-banner :title="$route.name"/>    
    <section class="container mx-auto pt-20">
      <div class="w-full pb-12">
        <h2 class="text-3xl font-bold mb-7 title relative">{{ header.titulo }}</h2>
        <div v-html="header.cuerpo" style="white-space: pre-wrap" align="justify"></div>
      </div>
    </section>
  </div>
</template>

<script>
import CtaBanner from '../../components/common/CtaBanner.vue';
import CtaTable from '../../components/CtaTable.vue';
import CtaDescription from '../../components/CtaDescription.vue';
import API from "@/api";
import {mapState} from "vuex";
import pdf from 'vue-pdf';

export default {
  name: "Trabajos",
  components: {
    CtaBanner,
    CtaTable,
    CtaDescription,
    pdf
    
  },
  data() {
    return {
      trabajos: [],
      header: {}
    };
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  created() {
    this.getTrabajos();
    this.getInfo();
  },
  methods: {
    async getTrabajos() {
      const { data } = await API.getTrabajos({ evento_fk: this.idEventoActual });
      this.trabajos = data.results;
    },
    async getInfo() {
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json"
      };
      const { data } = await API.getInfo(params);
      let items = data.results;
      for (let i in items){
        this.header = (items[i].codigo === 'AU20') ? items[i] : this.header;
      }
      console.log("header",this.header);
    }
  },
}
</script>

<style >
  .color_a {
      color: #0d6efd;
  }
  
  
</style>
